<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">
                {{
                  $route.params.id
                    ? $t("customerMsgs.editCustomer")
                    : $t("customerMsgs.addCustomer")
                }}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <div class="new-customer-info">
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div
                    class="form-group col-md-12"
                    v-if="$route.params.id"
                    id="custNumberRow"
                  >
                    <label for="customerNumber">{{
                      $t("customerMsgs.custNum")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="isCustNumFormatInvalid ? 'is-invalid' : ''"
                      id="customerNumber"
                      :placeholder="$t('customerMsgs.custNumPlaceholder')"
                      v-model="customerNumber"
                      v-mask="custNumMask"
                      :disabled="getLoggedInUser.role !== USER_ROLES.ADMIN"
                      required
                    />
                    <div class="invalid-feedback" v-if="isCustNumFormatInvalid">
                      {{ $t("customerMsgs.custNumFormatInvalid") }}
                    </div>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="name">{{ $t("name") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      :placeholder="$t('customerMsgs.namePlaceholder')"
                      v-model="name"
                      required
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="street">{{ $t("customerMsgs.street") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="street"
                      :placeholder="$t('customerMsgs.street')"
                      v-model="street"
                      required
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="postCode">{{
                      $t("customerMsgs.postalCode")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="postCode"
                      :placeholder="$t('customerMsgs.postalCode')"
                      v-model="postCode"
                      required
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="town">{{ $t("customerMsgs.city") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="town"
                      :placeholder="`${$t('customerMsgs.city')} / ${$t(
                        'customerMsgs.town'
                      )}`"
                      v-model="town"
                      required
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <div
                      class="custom-control custom-switch custom-switch-color custom-control-inline"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input bg-success"
                        id="customSwitch02"
                        v-model="enableRevFactor"
                      />
                      <label
                        class="custom-control-label"
                        for="customSwitch02"
                        >{{ $t("customerMsgs.enableRevFactorMsg") }}</label
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-12" v-if="enableRevFactor">
                    <label for="revenueFactor">{{
                      $t("customerMsgs.revFactor")
                    }}</label>
                    <currency-input
                      class="form-control"
                      id="revenueFactor"
                      :placeholder="`${$t('customerMsgs.revFactor')} / ${$t(
                        'customerMsgs.discountRate'
                      )}`"
                      v-model="revenueFactor"
                      :currency="null"
                      :precision="2"
                      :allow-negative="false"
                      required
                    />
                  </div>
                </div>

                <div class="text-right">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="isLoading"
                    style="min-width: 100px"
                  >
                    <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                    <span v-else>{{
                      $route.params.id ? $t("update") : $t("add")
                    }}</span>
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { USER_ROLES } from "../../common/constants";

export default {
  name: "PostCustomer",

  data() {
    return {
      USER_ROLES,

      name: "",
      customerNumber: null,
      revenueFactor: null,
      street: "",
      postCode: "",
      town: "",
      enableRevFactor: false,

      isCustNumFormatInvalid: false,

      isLoading: false,
    };
  },

  methods: {
    ...mapActions(["createCustomer", "updateCustomer", "getCustomer"]),

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },

    custNumMask(value) {
      const mask = ["K"];
      for (let index = 1; index < value.length; index++) {
        mask.push(/\d+/);
      }
      return mask;
    },

    async onSubmit() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.makeToast("danger", this.$t("formErrorMsg"));
      // } else {
      this.isLoading = true;

      if (this.customerNumber && !this.customerNumber.match(/^K\d+$/g)) {
        this.isCustNumFormatInvalid = true;
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));

        // Scrolling to the invalid id item.
        this.$nextTick(() => {
          window.scrollTo({
            top: document.querySelector("#custNumberRow").offsetTop,
            left: document.querySelector("#custNumberRow").offsetLeft,
            behavior: "smooth",
          });
        });
      } else {
        try {
          const customerData = {
            name: this.name,
            revenue_factor: this.enableRevFactor ? this.revenueFactor : 1,
            street: this.street,
            post_code: this.postCode,
            town: this.town,
            ...(this.customerNumber && {
              customer_number: this.customerNumber,
            }),
          };
          if (this.$route.params.id) {
            await this.updateCustomer({
              id: this.$route.params.id,
              data: customerData,
            });
            this.makeToast("success", this.$t("customerMsgs.customerUpdated"));
          } else {
            await this.createCustomer(customerData);
            this.makeToast("success", this.$t("customerMsgs.customerAdded"));
          }
          this.isLoading = false;
          this.$router.push({ name: "list-customers" });
        } catch (error) {
          this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        }
      }

      this.isLoading = false;
    },
  },

  // Reset data on before entering the route.
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.customerNumber = vm.revenueFactor = null;
      vm.name = vm.street = vm.postCode = vm.town = "";
      vm.enableRevFactor = null;
    });
  },

  async mounted() {
    if (this.$route.params.id) {
      try {
        const customer = (await this.getCustomer(this.$route.params.id)).data;
        this.name = customer.name;
        this.customerNumber = customer.customer_number;
        this.revenueFactor = customer.revenue_factor;
        this.street = customer.street;
        this.postCode = customer.post_code;
        this.town = customer.town;
      } catch (err) {
        this.$router.push({ name: "not-found" });
      }
      this.enableRevFactor = this.revenueFactor !== 1;
    }
  },

  computed: {
    ...mapGetters(["getLoggedInUser"]),
  },
};
</script>
